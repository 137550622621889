import { SpinachApp } from '@spinach-clients/shared';

import './App.css';

/**
 * Used to initialize any chrome-specific dependencies that may arise in the future
 */
function SpinachChromeApp() {
    return <SpinachApp />;
}

export default SpinachChromeApp;
