import { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';
import { ClientEventType, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import { AGENT_SAYINGS, patchVideoAgentSession, postVideoAgentChatMessage } from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useActivityTracking } from '../../useExperienceTracking';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function getValidatedJumpToTopic(command: string): number {
    // TODO refactor out the comands / validation
    const supportedCommandTriggers = [
        'go to ',
        'goto ',
        'jump to ',
        'jump ',
        '/topic jump ',
        '/topic goto ',
        '/spinach jump ',
        'spinach jump ',
    ];
    const { remainingCommand: topicNumberText } = getCommandContents(command, supportedCommandTriggers);

    const topicNumber = parseInt(topicNumberText);

    if (isNaN(topicNumber)) {
        return -1;
    }

    return topicNumber;
}

export function useJumpToTopicNumber(say: SayFunction) {
    const {
        state: { session },
        setSession,
    } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    return useCallback(
        async (topicNumber: number, triggerMedium = AgentCommandType.Voice) => {
            if (!session) {
                return;
            }

            const updatedSession = session.withJumpedToTopicByNumber(topicNumber);

            setSession(updatedSession);

            trackActivity(ClientEventType.VideoAgentActivity, 'Jump to Topic', {
                TriggeredBy: triggerMedium,
                JumpTo: topicNumber,
                JumpFrom: session.currentTopicIndex !== undefined ? session.currentTopicIndex + 1 : undefined,
                ...session?.analyticsPayload,
            });

            if (triggerMedium === AgentCommandType.Voice || session.isChatCommandAudioOutputEnabled) {
                say(AGENT_SAYINGS.JumpingToTopic);
            }

            let chatPayload: PostVideoAgentChatMessageRequest | null = null;
            if (updatedSession.currentTopic) {
                chatPayload = {
                    message: `Jumping to topic "${updatedSession.currentTopic.title}"`,
                    botId: updatedSession.botId,
                };
            }

            await Promise.all([
                patchVideoAgentSession(updatedSession.toJSON()),
                chatPayload ? postVideoAgentChatMessage(chatPayload) : undefined,
            ]);
        },
        [session, setSession, trackActivity, say]
    );
}
