import { TextField } from '@material-ui/core';
import { Titles } from '@spinach-clients/constants';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, HowDidYouHearCode, HowDidYouHearMap } from '@spinach-shared/types';

import { ReactComponent as SLogo } from '../../assets/s-logo.svg';
import { useLandingAnalytic } from '../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../styles';
import { ListItemValue } from '../../types';
import { AnonymousUserTracker, withContentMasking } from '../../utils';
import { DropDown, Spacing, SpinachStationary, ViewContainer } from '../common';
import { useSpinachInputStyles } from '../input';
import { PrimaryButton, ScrollArea } from '../stand-up';

export const ValidationLabel = styled.span`
    color: ${(props) => props.theme.secondary.midnight};
    font-size: 12px;
    min-height: 12px;
    width: 100%;
    text-align: left;
`;

export const NextButtonContainer = styled.div`
    justify-content: center;
    align-items: center;
`;

const NavButtonsContainer = styled(NextButtonContainer)`
    flex-direction: row;
    justify-content: center;
    display: flex;
    width: 100%;
`;

export type PersonalAndCompanyProps = {
    user: ClientUser;
    firstName: string;
    lastName: string;
    companyName: string;
    howDidYouHear: string;
    howDidYouHearOther: string;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setCompanyName: (companyName: string) => void;
    setHowDidYouHear: (howDidYouHear: string) => void;
    setHowDidYouHearOther: (howDidYouHear: string) => void;
    onSubmit: () => Promise<void>;
};

const Stationary = styled(SpinachStationary)`
    align-items: flex-start;
    text-align: left;
    padding: 25px;
`;

const ExpandingSpacer = styled(Spacing)`
    flex-grow: 1;
`;

function useOnMountAnalytics(email: string, name: string) {
    useEffect(() => {
        AnonymousUserTracker.trackEvent(ClientEventType.UserLandedOnContactConfirmationPage, {
            Email: email,
            UserName: name,
        });
    }, []);
}

function useInputValidation(input: string, errorMessage: string): string {
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!hasStartedTyping) {
            setHasStartedTyping(true);
        } else {
            const spacesReplaced = input.replace(/ /g, '');
            const validationMessage = spacesReplaced.length ? '' : errorMessage;
            setError(validationMessage);
        }
    }, [input]);

    return error;
}

export function PersonalAndCompany({
    user,
    firstName,
    lastName,
    companyName,
    howDidYouHear,
    howDidYouHearOther,
    setFirstName,
    setLastName,
    setCompanyName,
    setHowDidYouHear,
    setHowDidYouHearOther,
    onSubmit,
}: PersonalAndCompanyProps) {
    const { email } = user;
    useLandingAnalytic(ClientEventType.UserLandedOnPersonalPage);
    const nameError = useInputValidation(firstName, 'no name, no game.');
    const classes = useSpinachInputStyles({ value: '' });

    const otherValidation = Boolean(
        (howDidYouHear === HowDidYouHearCode.Other && howDidYouHearOther.length) ||
            howDidYouHear !== HowDidYouHearCode.Other
    );

    const cannotProceed = Boolean(
        nameError || !firstName || !lastName || !howDidYouHear || !companyName || !otherValidation
    );
    useOnMountAnalytics(email, `${firstName} ${lastName}`);

    const defaultDropDown = howDidYouHear
        ? Object.values(HowDidYouHearMap).find((obj) => obj.code === (howDidYouHear as HowDidYouHearCode))
        : undefined;

    return (
        <ViewContainer>
            <Stationary>
                <SLogo />
                <HeaderThreeOnboard>We're glad you're here</HeaderThreeOnboard>
                <Spacing factor={1 / 2} />

                <ScrollArea style={{ padding: '0px' }}>
                    <BodyBigOnboard style={{ textAlign: 'center' }}>
                        Tell us a little bit about yourself.
                    </BodyBigOnboard>

                    <Spacing />
                    <div style={{ marginLeft: '10%', height: '320px' }}>
                        <BodyBigOnboard>Your name</BodyBigOnboard>

                        {/* need to style these especially */}
                        <div style={{ display: 'flex-inline' }}>
                            <TextField
                                value={firstName}
                                InputProps={{ classes: { root: classes.base } }}
                                {...withContentMasking(classes.root)}
                                onChange={(e) => setFirstName(e.target.value)}
                                style={{ marginRight: '10px' }}
                                inputProps={{ maxLength: 50 }}
                                autoFocus
                                placeholder="First"
                            />
                            <TextField
                                value={lastName}
                                InputProps={{ classes: { root: classes.base } }}
                                {...withContentMasking(classes.root)}
                                onChange={(e) => setLastName(e.target.value)}
                                inputProps={{ maxLength: 50 }}
                                placeholder="Last"
                            />
                        </div>
                        <ValidationLabel>{nameError ?? ' '}</ValidationLabel>

                        <Spacing />
                        <BodyBigOnboard>Company</BodyBigOnboard>

                        {/* keep the company name parsing? */}
                        <TextField
                            style={{ width: '88%', maxWidth: '356px' }}
                            InputProps={{ classes: { root: classes.base } }}
                            inputProps={{ maxLength: 50 }}
                            {...withContentMasking(classes.root)}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Your Company Name"
                            value={companyName}
                        />

                        <Spacing />

                        <BodyBigOnboard>How did you hear about us?</BodyBigOnboard>
                        <Spacing factor={1 / 3} />
                        <div style={{ width: '230px' }}>
                            <DropDown
                                title={Titles.SelectAnAnswer}
                                values={Object.values(HowDidYouHearMap) as ListItemValue<HowDidYouHearCode>[]}
                                handleSelection={setHowDidYouHear}
                                defaultValue={defaultDropDown?.label}
                            />
                        </div>
                        {howDidYouHear === HowDidYouHearCode.Other ? (
                            <TextField
                                value={howDidYouHearOther}
                                style={{ paddingTop: '5%' }}
                                onChange={(e) => setHowDidYouHearOther(e.target.value)}
                                placeholder="Where?"
                                InputProps={{ classes: { root: classes.base } }}
                                inputProps={{ maxLength: 50 }}
                                {...withContentMasking(classes.root)}
                            />
                        ) : null}
                    </div>
                </ScrollArea>

                <ExpandingSpacer />

                <NavButtonsContainer>
                    <PrimaryButton
                        title={Titles.Next}
                        disabled={cannotProceed}
                        onClick={async () => {
                            await onSubmit();
                        }}
                    />
                </NavButtonsContainer>
            </Stationary>
        </ViewContainer>
    );
}
